import React from 'react';
import {
  useAccountScoreSettingQuery,
  useUpdateClientAccountScoreSettingAccountClosingMonthMutation,
  AccountScoreSettingAccountClosingMonth,
} from 'api';

export default function Input({priority}: {priority: number}) {
  const [update] =
    useUpdateClientAccountScoreSettingAccountClosingMonthMutation({});
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const selected =
    accountScoreSetting?.clientAccountScoreSettingAccountClosingMonths?.find(
      (month) => month.priority === priority,
    )?.beforeMonth ?? '';
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  return (
    <div className="flex items-center gap-2">
      <div className="text-sm font-bold w-12 text-center flex items-center">
        <span>優先度{priority}</span>
      </div>
      <select
        className="form-select border-c-border rounded text-sm h-8 pt-[3px] w-32 cursor-pointer"
        value={selected}
        onChange={(e) => {
          update({
            variables: {
              attributes: {
                beforeMonth: Number(e.target.value),
                priority,
              },
            },
          });
        }}>
        <option value={''}></option>
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={option}
              disabled={accountScoreSetting?.clientAccountScoreSettingAccountClosingMonths
                ?.map(
                  (range: AccountScoreSettingAccountClosingMonth) =>
                    range?.beforeMonth,
                )
                ?.includes(option)}>
              {option}
            </option>
          );
        })}
      </select>
      ヶ月前
    </div>
  );
}
