import React from 'react';
import Input from './Input';
import Slider from './Slider';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex w-full items-center justify-between">
            <div className="flex w-full items-center justify-between">
              <div>
                <h2 className="text-base m-0 font-bold">部署</h2>
                <p className="m-0 text-sm">
                  重要度を高める部署のキーワードを入力してください。
                </p>
              </div>
              <Slider />
            </div>
          </div>
          <Input priority={1} />
          <Input priority={2} />
          <Input priority={3} />
          <Input priority={4} />
          <Input priority={5} />
        </div>
      </div>
    </div>
  );
};
