import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import moment from 'moment';
import {Button, Form, Select, DatePicker, Popover} from 'components/antd';
import {prospectStages} from 'helpers/prospectStages';
import {
  ProspectPool,
  useActionsByProspectPoolQuery,
  useCreateManualMailCommentProspectActivityMutation,
} from 'api';
import {useHistory} from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/ja_JP';
import {Quill, QuillWrapper} from 'components/Ui';
import {ArrowDown} from 'components/Ui/Icon';

type Props = {
  prospectPool: ProspectPool;
  actionUuid: string;
};

export default ({prospectPool, actionUuid}: Props) => {
  const history = useHistory();

  const [createManualMailCommentProspectActivity, {loading}] =
    useCreateManualMailCommentProspectActivityMutation({
      onCompleted: () => {
        history.push(
          location.pathname.replace('workflow', 'timeline') + location.search,
        );
      },
      refetchQueries: ['actionCounts'],
    });

  const currentStageValue = () => {
    return prospectStages.find((stage) => {
      return stage.name === prospectPool?.stage;
    });
  };

  const {data: {actionsByProspectPool = []} = {}} =
    useActionsByProspectPoolQuery({
      variables: {
        prospectPoolUuid: prospectPool.uuid,
      },
      fetchPolicy: 'cache-and-network',
    });

  const actionStatus = actionsByProspectPool.find(
    (action) => action.uuid === actionUuid,
  )?.status;

  const actionStartDate = actionsByProspectPool.find(
    (action) => action.uuid === actionUuid,
  )?.startDate;

  const dateTimeFormat = 'YYYY-MM-DD HH:mm';
  const dateFormat = 'YYYY-MM-DD';

  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      stage: currentStageValue()?.id,
      recordedAt: moment().startOf('hour').format(dateTimeFormat),
      comment: '',
      commentHtml: '',
      isCompleted: false,
      nextActionDate: moment(actionStartDate, dateFormat),
    },
    onSubmit: (values) => {
      createManualMailCommentProspectActivity({
        variables: {
          prospectPoolUuid: prospectPool.uuid,
          actionUuid: actionUuid,
          isCompleted: values.isCompleted,
          nextActionDate: values.nextActionDate,
          attributes: {
            stage: values.stage,
            recordedAt: values.recordedAt,
            comment: values.comment,
            commentHtml: values.commentHtml,
          },
        },
      });
    },
  });

  const stepName = actionsByProspectPool.find(
    (action) => action.uuid === actionUuid,
  )?.step?.name;

  return (
    <Container>
      <Form onFinish={formik.handleSubmit}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
          }}>
          <Form.Item>
            <Label>ステップ</Label>
            <div style={{fontSize: '14px'}}>{stepName}</div>
          </Form.Item>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <Form.Item>
            <Label>ステージ</Label>
            <Select
              defaultValue={formik.values.stage}
              value={formik.values.stage}
              onChange={(value) => formik.setFieldValue('stage', value)}>
              {prospectStages.map((stage) => (
                <Select.Option key={stage.id} value={stage.id}>
                  {stage.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Label>日時</Label>
            <DatePicker
              showTime
              allowClear={false}
              format={dateTimeFormat}
              defaultValue={moment(formik.values.recordedAt, dateTimeFormat)}
              locale={locale}
              onChange={(date) => formik.setFieldValue('recordedAt', date)}
              minuteStep={15}
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Label>ネクストアクション日</Label>
          <DatePicker
            allowClear={false}
            format={dateFormat}
            value={moment(formik.values.nextActionDate, dateFormat)}
            locale={locale}
            onChange={(date) => formik.setFieldValue('nextActionDate', date)}
            disabled={formik.values.isCompleted}
          />
        </Form.Item>

        <QuillWrapper
          style={{
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            borderRadius: 0,
          }}>
          <Quill
            placeholder="コメント"
            theme="snow"
            onChange={(content, delta, source, editor) => {
              formik.setFieldValue('commentHtml', content);
              formik.setFieldValue('comment', editor.getText());
            }}
            modules={{
              toolbar: [
                {header: [1, 2, 3, false]},
                'bold',
                'italic',
                'underline',
                'code-block',
                'link',
              ],
            }}
            bounds={'#quill-container'}
          />
          <div id="quill-container" />
        </QuillWrapper>
        <Actions>
          <Button
            style={{
              backgroundColor: `${formik.values.isCompleted ? '#61CF8D' : ''}`,
            }}
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!formik.isValid || loading}>
            {formik.values.isCompleted
              ? 'ステップを完了して登録'
              : 'ステップを完了しないでログのみ登録'}
          </Button>
          {actionStatus === 'open' && (
            <Popover
              content={
                <PopoverContent>
                  <PopoverItem
                    onClick={() => {
                      setIsPopoverVisible(false);
                      formik.setFieldValue('isCompleted', false);
                    }}>
                    <div>ステップを完了しないでログのみ登録</div>
                    <Description>
                      アクションは完了せずアクションボードに残ります。
                    </Description>
                  </PopoverItem>
                  <PopoverItem
                    onClick={() => {
                      setIsPopoverVisible(false);
                      formik.setFieldValue('isCompleted', true);
                    }}>
                    <div>ステップを完了して登録</div>
                    <Description>
                      アクションは完了しアクションボードから削除されます。
                    </Description>
                  </PopoverItem>
                </PopoverContent>
              }
              trigger="click"
              visible={isPopoverVisible}
              onVisibleChange={(visible) => setIsPopoverVisible(visible)}
              placement="bottomRight">
              <Button
                style={{
                  backgroundColor: `${
                    formik.values.isCompleted ? '#61CF8D' : ''
                  }`,
                  borderLeft: `${
                    formik.values.isCompleted ? '2px solid #3CB26C' : ''
                  }`,
                }}
                className="ant-btn-right"
                type="primary"
                onClick={() => setIsPopoverVisible(true)}>
                <ArrowDown />
              </Button>
            </Popover>
          )}
        </Actions>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 25px;

  .ant-form-item,
  .ant-picker {
    width: 230px;
  }

  .ant-radio-group {
    position: relative;
    top: 12px;
  }

  .ql-toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
  }

  .ql-editor {
    max-height: 300px;
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: var(--text-color-2);
  margin-bottom: 5px;
`;

const Actions = styled.div`
  margin-top: 65px;
  display: flex;
  justify-content: flex-end;

  button {
    width: 270px;
    height: 35px;
    font-size: 14px;
    border: none;
  }

  .ant-btn-right {
    width: 36px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 1px solid var(--blue-200);
  }
  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: #ffffff;
    }
  }
`;

const PopoverContent = styled.div`
  width: 306px;
  height: 138px;
  display: flex;
  flex-direction: column;
  padding: 7px 0;
`;

const PopoverItem = styled.div`
  width: 306px;
  height: 69px;
  padding: 10px 0;
  padding-left: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    background: #f3f9ff;
    color: var(--primary);
  }
`;

const Description = styled.div`
  font-size: 10px;
  display: content;
`;
