import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const LeadSourceFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="配信停止" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio"
              checked={query.isUnsubscribed === false}
              onChange={() => setQuery({...query, isUnsubscribed: false})}
            />
            <span className="ml-2">配信</span>
          </label>
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio"
              checked={query.isUnsubscribed === true}
              onChange={() => setQuery({...query, isUnsubscribed: true})}
            />
            <span className="ml-2">配信停止</span>
          </label>
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, isUnsubscribed: null})} />
    </>
  );
};

export default LeadSourceFilter;
