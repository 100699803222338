import React from 'react';

type Props = {
  title: string;
  score: number;
  hasSetting?: boolean;
  target?: boolean;
  color?: string;
};

const ScoreItem = ({
  title,
  score,
  hasSetting = false,
  target = true,
  color = 'var(--score-green-foreground)',
}: Props) => {
  const NotTarget = () => {
    return (
      <span className="text-foreground-lighter font-bold text-xs w-20 text-center"> 
        対象外
      </span>
    );
  };

  const HasNoSetting = () => {
    return (
      <span className="text-foreground-lighter font-bold text-xs w-20 text-center">
        未設定
      </span>
    );
  };

  const Score = ({score}: {score: number; color: string}) => {
    return (
      <div className="flex items-center gap-1 w-20 justify-center">
        <div className="flex gap-[1px] items-center">
          <span
            className="bg-gray-200 h-[6px] w-4 rounded-l-full"
            style={{backgroundColor: score >= 1 ? color : ''}}
          />
          <span
            className="bg-gray-200 h-[6px] w-4"
            style={{backgroundColor: score >= 2 ? color : ''}}
          />
          <span
            className="bg-gray-200 h-[6px] w-4 rounded-r-full"
            style={{backgroundColor: score == 3 ? color : ''}}
          />
        </div>
        <span className="font-bold text-xs w-4">{score}</span>
      </div>
    );
  };

  const Status = () => {
    if (!target) {
      return <NotTarget />;
    } else if (!hasSetting) {
      return <HasNoSetting />;
    }
    return <Score score={score} color={color} />;
  };

  return (
    <div className="flex justify-between items-center w-40 gap-1">
      <h4 className="text-xs flex-1 text-right m-0">{title}</h4>
      <Status />
    </div>
  );
};

export default ScoreItem;
