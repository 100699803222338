import {CheckCircleIcon} from '@heroicons/react/20/solid';
import {useCurrentUserQuery} from 'api';
import React from 'react';

interface Props {
  lastName: string;
  firstName: string;
  department: string;
  position: string;
}

export default ({lastName, firstName, department, position}: Props) => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const isUserRoleAdmin = currentUser?.role === 'admin';

  return (
    <>
      <div className="flex items-center gap-1">
        {isUserRoleAdmin && (
          <CheckCircleIcon className="w-4 h-4 text-[#4fce82]" />
        )}
        <span className="m-0 text-base text-c-base leading-none truncate font-bold">
          {lastName} {firstName}
        </span>
      </div>
      <span className="text-xs text-c-light truncate leading-none">
        {department}
      </span>
      <span className="text-xs text-c-light truncate leading-none">
        {position}
      </span>
    </>
  );
};
