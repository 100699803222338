import React from 'react';
import styled from 'styled-components';
import {Form, Button, Input, message, Popover} from 'components/antd';
import {useParams} from 'react-router';
import {
  useUpdateContentMutation,
  useSendContentMailFormTestMailMutation,
} from 'api';
import useAutoSave from './useAutoSave';
import {Check, LoadingProcess, Copy} from 'components/Ui/Icon';
import CopyModal from './CopyModal';

interface Props {
  content: any;
  formik: any;
}

export default ({formik, content}: Props) => {
  const {id} = useParams<{id: string}>();
  const [updateContent] = useUpdateContentMutation();

  const [sendTestMail, {loading: sendTestMailLoading}] =
    useSendContentMailFormTestMailMutation({
      onCompleted: () => message.info('確認メールを送信しました'),
    });

  const onChangeTitle = React.useCallback(
    (e: any) =>
      updateContent({variables: {id, attributes: {title: e.target.value}}}),
    [],
  );

  const onClickSendMail = React.useCallback(() => {
    if (
      confirm(
        `${formik.values.email}にテストメールを送信します。\nよろしいですか？`,
      )
    ) {
      sendTestMail({variables: {uuid: id}});
    }
  }, []);

  const editingVersion = React.useMemo(
    () =>
      Math.max(
        ...content?.mailForm?.versions?.map((version: any) => version.version),
        0,
      ) + 1,
    [content],
  );

  const {saving} = useAutoSave(content, formik);

  const isChangedFromPreviousVersion = Object.keys(formik.values).some(
    (key) => formik.values[key] !== content.mailForm.versions[0]?.[key],
  );

  return (
    <Title>
      <Draft>Draft</Draft>
      <Version>V{editingVersion}</Version>
      <Input
        size="large"
        defaultValue={content?.title}
        onBlur={onChangeTitle}
      />
      <SaveStatus>
        {saving ? (
          <>
            保存中...
            <LoadingProcess className="rotating" />
          </>
        ) : (
          formik.dirty && (
            <>
              自動保存しました <Check />
            </>
          )
        )}
      </SaveStatus>

      <CopyButton
        content={<CopyModal formik={formik} content={content} />}
        placement="bottom"
        trigger="click">
        <Button type="primary" ghost>
          <Copy color="#78B3F5" />
          <span>コピー</span>
        </Button>
      </CopyButton>
      <Button
        type="default"
        loading={sendTestMailLoading}
        disabled={
          !formik.isValid ||
          !formik.values.messageMail ||
          saving ||
          sendTestMailLoading
        }
        onClick={onClickSendMail}>
        テスト送信
      </Button>
      <Form onFinish={formik.handleSubmit}>
        <Button
          type="primary"
          htmlType="submit"
          loading={formik.isSubmitting}
          disabled={!formik.isValid || saving || !isChangedFromPreviousVersion}>
          確定
        </Button>
      </Form>
    </Title>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;

  input {
    font-size: 18px;
    font-weight: bold;
    width: 50%;
    border: none;
    background: transparent;
    flex: 1;
  }

  .ant-input:focus {
    border-color: none !important;
    outline: 0 !important;
    box-shadow: none !important;
  }

  ul {
    list-style: none;
    overflow: hidden;
    font-weight: bold;
    font-size: 12px;
    color: var(--text-color-2);

    li {
      width: 100px;
      text-align: center;
      float: left;
    }
  }

  button {
    margin-left: 10px;
    box-shadow: none;
  }
`;

const Version = styled.span`
  background-color: var(--label09);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
`;

const Draft = styled.span`
  font-size: 12px;
  font-weight: bold;
  border-radius: 100px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  background: var(--border-color);
  color: var(--text-color-2);
  margin-right: 0.5rem;
`;

const SaveStatus = styled.span`
  margin: 0 1rem;
  color: var(--text-color-2);

  svg {
    width: 14px;
    height: 14px;
    position: relative;
    top: 1px;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .rotating {
    animation: rotating 2s linear infinite;
  }
`;

const CopyButton = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
    margin-right: 5px;
  }

  span {
    color: var(--primary);
  }
`;
