import React from 'react';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Panel from '../Panel';
import Title from './Title';
import Message from './Message';
import Detail from './Detail';
import {Content} from 'api';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id, version} = useParams<{id: string; version: string}>();
  const location = useLocation();

  const currentVersion = React.useMemo(
    () =>
      content?.mailForm?.versions.find((v) => v.version === Number(version)),
    [content, version],
  );

  return (
    <Container>
      <Title content={content} />
      <MailForm>
        <Menu>
          <MenuItem
            active={
              !location.pathname.startsWith(
                `/garage/contents/${id}/mail_form/versions/${version}/detail`,
              )
            }>
            <Link
              to={`/garage/contents/${id}/mail_form/versions/${version}${location.search}`}>
              メール本文
            </Link>
          </MenuItem>
          <MenuItem
            active={
              !!location.pathname.startsWith(
                `/garage/contents/${id}/mail_form/versions/${version}/detail`,
              )
            }>
            <Link
              to={`/garage/contents/${id}/mail_form/versions/${version}/detail${location.search}`}>
              送信項目
            </Link>
          </MenuItem>
        </Menu>
        <Content>
          <Form>
            <Switch>
              <Route path="/garage/contents/:id/mail_form/versions/:version/detail">
                <Detail version={currentVersion} />
              </Route>
              <Route>
                <Message version={currentVersion} />
              </Route>
            </Switch>
          </Form>
          <Panel content={content} />
        </Content>
      </MailForm>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const MailForm = styled.div`
  background: #fff;
  margin-top: 1.5rem;
  border-radius: 4px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  border-bottom: solid 1px var(--border-color);
`;

const MenuItem = styled.div<{active?: boolean}>`
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 3px;
  border-color: ${({active}) => (active ? 'var(--label09)' : 'transparent')};

  a {
    font-size: 14px;
    font-weight: ${({active}) => (active ? 700 : 400)};
    margin: 0 1rem;
    color: var(--text-color-1);
  }
`;

const Content = styled.div`
  display: flex;
`;

const Form = styled.div`
  flex: 1;
`;
