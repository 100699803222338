import React from 'react';
import styled from 'styled-components';
import Table from './Table';
import Summary from './Summary';
import {Input} from 'components/antd';
import Actions from './Actions';
import NewFilter from './NewFilter';
import {Lense} from 'components/Ui/Icon';
import useSearchParams from './useSearchParams';
import MergeProspectPool from './MergeProspectPool';
import {CheckedProspectPoolsContext} from './CheckedProspectPoolContext';

export default () => {
  const {query, setQuery} = useSearchParams();
  const searchProspectPools = (e: any) => {
    setQuery({
      searchWord: e.target.value,
      page: 1,
    });
  };
  const [checkedProspectPoolIds, setCheckedProspectPoolIds] = React.useState(
    [],
  );

  return (
    <Container>
      <Header>
        <Input
          type="search"
          name="search"
          placeholder="検索"
          prefix={<Lense />}
          onPressEnter={(e) => searchProspectPools(e)}
          onBlur={(e) => searchProspectPools(e)}
          defaultValue={query.searchWord}
          className="form-input w-48 h-10"
        />
        <NewFilter />
        {checkedProspectPoolIds?.length >= 2 && (
          <CheckedProspectPoolsContext.Provider
            value={{
              checkedProspectPoolIds,
              setCheckedProspectPoolIds,
            }}>
            <MergeProspectPool />
          </CheckedProspectPoolsContext.Provider>
        )}
        <div className="flex-1" />
        <Actions />
      </Header>
      <Summary />
      <CheckedProspectPoolsContext.Provider
        value={{
          checkedProspectPoolIds,
          setCheckedProspectPoolIds,
        }}>
        <Table />
      </CheckedProspectPoolsContext.Provider>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0.5rem;

  button {
    margin: 0;
    padding: 0;

    svg {
      position: relative;
      top: 2px;
    }
  }
`;
