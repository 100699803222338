import React from 'react';
import {useRecoilState} from 'recoil';
import {useActionsQuery} from 'api';
import useSearchParams from '../useSearchParams';
import checkedActionIdsAtom from '../checkedActionIdsAtom';
import {useParams} from 'react-router-dom';

const CheckHeader = () => {
  const {page, perPage, searchParams} = useSearchParams();
  const {poolId} = useParams<{poolId: string}>();
  const [checkedActionIds, setCheckedActionIds] =
    useRecoilState(checkedActionIdsAtom);

  const {data: {actions: {actions = []} = {}} = {}} = useActionsQuery({
    variables: {
      uuid: poolId,
      search: searchParams,
      page,
      perPage,
    },
    fetchPolicy: 'cache-and-network',
  });

  const ActionIds = React.useMemo(
    () => actions.map((action) => action.uuid),
    [actions],
  );

  const checked = React.useMemo(
    () => ActionIds.every((id) => checkedActionIds.includes(id)),
    [checkedActionIds, ActionIds],
  );

  const setIds = [...new Set([...ActionIds, ...checkedActionIds])];

  const handleChange = React.useCallback(
    (e: any) => {
      if (e.target.checked) {
        setCheckedActionIds(setIds);
      } else {
        setCheckedActionIds(
          setIds.filter((setId) => !ActionIds.includes(setId)),
        );
      }
    },
    [ActionIds],
  );

  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={handleChange}
      className="form-checkbox"
    />
  );
};

export default CheckHeader;
