import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const HasCompanyFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="企業名寄せ" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio"
              checked={query.hasCompany === true}
              onChange={() => setQuery({...query, hasCompany: true})}
            />
            <span className="ml-2">企業名寄せあり</span>
          </label>
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio"
              checked={query.hasCompany === false}
              onChange={() => setQuery({...query, hasCompany: false})}
            />
            <span className="ml-2">企業名寄せなし</span>
          </label>
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, hasCompany: null})} />
    </>
  );
};

export default HasCompanyFilter;
