import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useParams, useHistory} from 'react-router-dom';
import {ContentMailFormVersion} from 'api';

interface Props {
  mailFormVersion: ContentMailFormVersion;
}

export default ({mailFormVersion}: Props) => {
  const {id, version} = useParams<{id: string; version: string}>();
  const history = useHistory();

  return (
    <Container
      active={mailFormVersion.version === Number(version)}
      onClick={() =>
        history.push(
          `/garage/contents/${id}/mail_form/versions/${mailFormVersion.version}${location.search}`,
        )
      }>
      <Version active={mailFormVersion.version === Number(version)}>
        V{mailFormVersion.version}
      </Version>
      <Content>
        <h3>{mailFormVersion.title}</h3>
        {moment(mailFormVersion.createdAt).format('YYYY.MM.DD')}
      </Content>
    </Container>
  );
};

const Container = styled.div<{active?: boolean}>`
  display: flex;
  padding: 1rem;
  border-top: solid 1px #e1e6eb;
  cursor: pointer;

  background-color: ${({active}) => (active ? '#f8fbff' : '#fff')};

  &:hover {
    background: #f8fbff;
  }
`;

const Version = styled.span<{active?: boolean}>`
  background-color: ${({active}) =>
    active ? 'var(--label09)' : 'var(--text-color-3)'};
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  border-radius: 4px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
`;

const Content = styled.div`
  margin-left: 1rem;
  color: var(--text-color-1);

  h3 {
    color: var(--text-color-1);
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 3px;
  }
`;
