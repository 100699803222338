import React from 'react';
import {useParams} from 'react-router-dom';
import {Button, Dropdown, Menu} from 'components/antd';
import CreateProspectModal from './CreateProspectModal';
import UploadModal from './UploadModal';
import UploadWithTransitionItemsModal from './UploadWithTransitionItemsModal';
import {usePoolQuery} from 'api';
import {ImportProgressBar} from 'components/Ui';
import DownloadModal from './DownloadModal';
import useClientUser from 'hooks/useClientUser';
import MenuButton from './MenuButton';

export default () => {
  const {poolId} = useParams<{
    poolId: string;
  }>();
  const {isAdmin} = useClientUser();

  const [createModalVisible, setCreateModalVisible] = React.useState(false);
  const [uploadModalVisible, setUploadModalVisible] = React.useState(false);
  const [
    updateWithTransitionItemsVisible,
    setUploadWithTransitionItemsModalVisible,
  ] = React.useState(false);
  const [downloadModalVisible, setDownloadModalVisible] = React.useState(false);

  const {
    data: {
      pool: {
        processingImportProspect,
        processingProspectPoolTagUpdate,
        processingProspectPoolUserUpdate,
      } = {},
    } = {},
    refetch,
  } = usePoolQuery({
    variables: {uuid: poolId},
  });

  if (processingImportProspect) {
    return (
      <div className="w-[140px]">
        <ImportProgressBar
          percent={processingImportProspect.percent}
          refetch={refetch}
        />
      </div>
    );
  }
  if (processingProspectPoolTagUpdate) {
    return (
      <div className="w-[140px]">
        <ImportProgressBar
          percent={processingProspectPoolTagUpdate?.percent}
          refetch={refetch}
        />
      </div>
    );
  }

  if (processingProspectPoolUserUpdate) {
    return (
      <div className="w-[140px]">
        <ImportProgressBar
          percent={processingProspectPoolUserUpdate?.percent}
          refetch={refetch}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      {isAdmin && (
        <>
          <MenuButton />
          <Button
            className="w-36 h-9 rounded"
            onClick={() => setDownloadModalVisible(true)}>
            ダウンロード
          </Button>
        </>
      )}
      <Dropdown
        overlay={
          <Menu>
            {isAdmin && (
              <>
                <Menu.Item key="upload">
                  <a
                    target="_blank"
                    onClick={() => setUploadModalVisible(true)}>
                    ファイルアップロード
                  </a>
                </Menu.Item>
                <Menu.Item key="upload2">
                  <a
                    target="_blank"
                    onClick={() =>
                      setUploadWithTransitionItemsModalVisible(true)
                    }>
                    TELログ登録
                  </a>
                </Menu.Item>
              </>
            )}
            <Menu.Item key="create">
              <a target="_blank" onClick={() => setCreateModalVisible(true)}>
                個別入力
              </a>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight">
        <Button type="primary" className="w-36 h-9">
          ＋ リードを追加
        </Button>
      </Dropdown>
      <UploadModal
        visible={uploadModalVisible}
        onClose={() => setUploadModalVisible(false)}
      />
      <UploadWithTransitionItemsModal
        visible={updateWithTransitionItemsVisible}
        onClose={() => setUploadWithTransitionItemsModalVisible(false)}
      />
      <CreateProspectModal
        isModalVisible={createModalVisible}
        setIsModalVisible={setCreateModalVisible}
      />
      <DownloadModal
        visible={downloadModalVisible}
        onClose={() => setDownloadModalVisible(false)}
      />
    </div>
  );
};
