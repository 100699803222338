import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useParams} from 'react-router';
import Panel from './Panel';
import Title from './Title';
import {Content} from 'api';
import Document from './Document';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id} = useParams<{id: string}>();
  const location = useLocation();

  return (
    <Container>
      <Title content={content} />
      <Main>
        <Menu>
          <MenuItem active={true}>
            <Link to={`/garage/contents/${id}/document${location.search}`}>
              ドキュメント
            </Link>
          </MenuItem>
        </Menu>
        <Content>
          <Document content={content} />
          <Panel />
        </Content>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Main = styled.div`
  background: #fff;
  margin-top: 1.5rem;
  border-radius: 4px;
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  border-bottom: solid 1px var(--border-color);
`;

const MenuItem = styled.div<{active?: boolean}>`
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 3px;
  border-color: ${({active}) => (active ? 'var(--label09)' : 'transparent')};

  a {
    font-size: 14px;
    font-weight: ${({active}) => (active ? 700 : 400)};
    margin: 0 1rem;
    color: var(--text-color-1);
  }
`;

const Content = styled.div`
  display: flex;
`;
