import React from 'react';
import useSearchParams from '../../../../useSearchParams';
import Header from '../../Header';
import Footer from '../Footer';
import styled from 'styled-components';
import Select from './Select';
import UrlCheckboxGroup from './UrlCheckboxGroup';

const MailCampaignFilter = () => {
  const {query, setQuery} = useSearchParams();

  const isClickDisable = query.isTargetAllMailCampaigns
    ? !query.isTargetAllMailCampaigns
    : !query.mailCampaignId;

  const isOpenDisable = query.isTargetAllMailCampaigns
    ? !query.isTargetAllMailCampaigns
    : !query.mailCampaignId || query.mailCampaignUrlIds?.length > 0;

  const resetMailCampaignQuery = () => {
    setQuery({
      ...query,
      isTargetAllMailCampaigns: !query.isTargetAllMailCampaigns,
      mailCampaignId: '',
      mailCampaignUrlIds: [],
      mailCampaignOpenedFromDate: '',
      mailCampaignOpenedToDate: '',
      mailCampaignClickedFromDate: '',
      mailCampaignClickedToDate: '',
      isBouncedMailCampaignSend: false,
      isUnsubscribedMailCampaignSend: false,
    });
  };

  return (
    <>
      <Header title="メールキャンペーン" />
      <div className="px-6 py-4 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="h-full bg-white px-6 py-3 rounded flex flex-col gap-2 overflow-y-scroll">
          <div className="text-c-base font-bold">メールキャンペーン</div>
          <div>
            <div className="mb-1">
              <label className="flex items-center">
                <input
                  type="radio"
                  checked={query.isTargetAllMailCampaigns}
                  onChange={resetMailCampaignQuery}
                  className="form-radio"
                />
                <span className="ml-2">全て</span>
              </label>
            </div>
            <div>
              <label className="flex items-center">
                <input
                  type="radio"
                  checked={!query.isTargetAllMailCampaigns}
                  onChange={resetMailCampaignQuery}
                  className="form-radio"
                />
                <span className="ml-2">キャンペーン指定</span>
              </label>
            </div>
          </div>
          <div className="min-h-[16rem] p-2 overflow-y-scroll">
            <Select />
            <UrlCheckboxGroup />
          </div>
          <div className="flex flex-col border-t border-c-border">
            <div className="mt-2 text-c-base font-bold">最新開封日</div>
            <CustomInput>
              <input
                type="date"
                disabled={isOpenDisable}
                value={query.mailCampaignOpenedFromDate}
                onChange={(e: any) =>
                  setQuery({
                    mailCampaignOpenedFromDate: e.target.value,
                    isBouncedMailCampaignSend: false,
                  })
                }
                className="form-input p-2 leading-none text-sm"
              />

              <span className="mx-2">~</span>
              <input
                type="date"
                disabled={isOpenDisable}
                value={query.mailCampaignOpenedToDate}
                onChange={(e: any) =>
                  setQuery({
                    mailCampaignOpenedToDate: e.target.value,
                    isBouncedMailCampaignSend: false,
                  })
                }
                className="form-input p-2 leading-none text-sm"
              />
            </CustomInput>
          </div>
          <div className="flex flex-col border-t border-c-border">
            <div className="mt-2 text-c-base font-bold">最新クリック日</div>
            <CustomInput>
              <input
                type="date"
                disabled={isClickDisable}
                value={query.mailCampaignClickedFromDate}
                onChange={(e: any) =>
                  setQuery({
                    mailCampaignClickedFromDate: e.target.value,
                    isBouncedMailCampaignSend: false,
                  })
                }
                className="form-input p-2 leading-none text-sm"
              />
              <span className="mx-2">~</span>
              <input
                type="date"
                disabled={isClickDisable}
                value={query.mailCampaignClickedToDate}
                onChange={(e: any) =>
                  setQuery({
                    mailCampaignClickedToDate: e.target.value,
                    isBouncedMailCampaignSend: false,
                  })
                }
                className="form-input p-2 leading-none text-sm"
              />
            </CustomInput>
          </div>
          <div className="flex flex-col border-t border-c-border justify-start gap-1">
            <div className="mt-2 text-c-base font-bold">バウンス</div>
            <input
              type="checkbox"
              disabled={
                !query.mailCampaignId || query.mailCampaignUrlIds?.length > 0
              }
              checked={query.isBouncedMailCampaignSend}
              className="form-checkbox"
              onChange={(e: any) => {
                setQuery({
                  ...query,
                  mailCampaignClickedFromDate: '',
                  mailCampaignClickedToDate: '',
                  mailCampaignOpenedFromDate: '',
                  mailCampaignOpenedToDate: '',
                  isBouncedMailCampaignSend: e.target.checked,
                  isUnsubscribedMailCampaignSend: false,
                });
              }}
            />
          </div>
          <div className="flex flex-col border-t border-c-border justify-start gap-1">
            <div className="mt-2 text-c-base font-bold">配信停止</div>
            <input
              type="checkbox"
              disabled={
                !query.mailCampaignId || query.mailCampaignUrlIds?.length > 0
              }
              checked={query.isUnsubscribedMailCampaignSend}
              className="form-checkbox"
              onChange={(e: any) => {
                setQuery({
                  ...query,
                  isUnsubscribedMailCampaignSend: e.target.checked,
                  isBouncedMailCampaignSend: false,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setQuery({
            ...query,
            isTargetAllMailCampaigns: false,
            mailCampaignId: '',
            mailCampaignUrlIds: [],
            mailCampaignOpenedFromDate: '',
            mailCampaignOpenedToDate: '',
            mailCampaignClickedFromDate: '',
            mailCampaignClickedToDate: '',
            isBouncedMailCampaignSend: false,
            isUnsubscribedMailCampaignSend: false,
          })
        }
      />
    </>
  );
};

const CustomInput = styled.div`
  /* カレンダーアイコンを非表示にし、inputをクリックでカレンダーを表示させる */
  input[type='date'] {
    position: relative;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

export default MailCampaignFilter;
