import React from 'react';
import styled from 'styled-components';
import {Button, Select} from 'components/antd';
import Table from './Table';
import Title from './Title';
import useSearchParams from './useSearchParams';
import NewModal from './NewModal';
import DownloadModal from './DownloadModal';
import Search from './Search';

export default () => {
  const {query, setQuery} = useSearchParams();
  const [visible, setVisible] = React.useState(false);
  const active = 'font-bold text-foreground bg-[#FFFFFF]';
  const inActive =
    'hover:text-foreground hover:bg-[#FAFBFB] text-foreground-lighter';
  const [downloadModalVisible, setDownloadModalVisible] = React.useState(false);

  return (
    <Container>
      <Title title="キャンペーン一覧" />
      <Contents>
        <Header>
          <Menu>
            <NavMenu>
              <NavItem
                active={query.target === 'prospects'}
                onClick={() =>
                  setQuery({status: query.status, page: 1, target: 'prospects'})
                }>
                リード
              </NavItem>
              <NavItem
                active={query.target === 'preleads'}
                onClick={() =>
                  setQuery({status: query.status, page: 1, target: 'preleads'})
                }>
                営業リスト
              </NavItem>
            </NavMenu>
          </Menu>
          <button
            className="cursor-pointer bg-white border border-border text-sm hover:opacity-50 rounded items-center justify-center h-10 px-6 mr-4"
            onClick={() => setDownloadModalVisible(true)}>
            ダウンロード
          </button>
          <Button
            type="primary"
            onClick={() => setVisible(true)}
            className="w-48 h-10">
            + 新規キャンペーンを作成
          </Button>
        </Header>
        <div className="flex mt-5">
          <div className="flex border-none bg-[#F3F5F7] p-0.5 cursor-pointer">
            <div
              className={`flex rounded-sm px-6 py-2 ${
                !query.status ? active : inActive
              }`}
              onClick={() =>
                setQuery({status: null, page: 1, target: query.target})
              }>
              全て
            </div>
            <div
              className={`flex rounded-sm px-6 py-2 ${
                query.status === 'sent' ? active : inActive
              } `}
              onClick={() =>
                setQuery({status: 'sent', page: 1, target: query.target})
              }>
              送信済
            </div>
            <div
              className={`flex rounded-sm px-6 py-2 ${
                query.status === 'ready' ? active : inActive
              }`}
              onClick={() =>
                setQuery({status: 'ready', page: 1, target: query.target})
              }>
              送信予定
            </div>
            <div
              className={`flex rounded-sm px-6 py-2 ${
                query.status === 'draft' ? active : inActive
              }`}
              onClick={() =>
                setQuery({status: 'draft', page: 1, target: query.target})
              }>
              下書き
            </div>
          </div>
        </div>
        <div className="flex mt-5 gap-3 justify-between">
          <div className="flex items-center gap-3">
            <div className="leading-8 text-foreground-light">並び順</div>
            <CustomSelect
              onChange={(value: string) => {
                setQuery({orderBy: value, page: 1});
              }}
              defaultValue={query.orderBy || 'created_at_desc'}
              options={[
                {
                  value: 'created_at_desc',
                  label: '作成日 降順',
                },
                {
                  value: 'created_at_asc',
                  label: '作成日 昇順',
                },
                {
                  value: 'send_at_desc',
                  label: '送信日時 降順',
                },
                {
                  value: 'send_at_asc',
                  label: '送信日時 昇順',
                },
              ]}
            />
          </div>
          <Search />
        </div>
        <Table />
      </Contents>
      <NewModal visible={visible} setVisible={setVisible} />
      <DownloadModal
        visible={downloadModalVisible}
        onClose={() => setDownloadModalVisible(false)}
        target={query.target}
      />
    </Container>
  );
};

const CustomSelect = styled(Select)`
  width: 135px;
`;

const Container = styled.div`
  padding: 30px;
  background: #f3f5f7;
  height: calc(100vh - 60px);
  overflow-y: scroll;
`;

const Contents = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 4px;
`;

const Header = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: center;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: var(--text-color-3);
  margin-right: auto;
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NavItem = styled.div<{active?: boolean}>`
  display: inline-block;
  padding-bottom: 15px;
  font-size: 14px;
  cursor: pointer;
  color: ${({active}) =>
    active ? 'var(--text-color-0)' : 'var(--text-color-3)'};
  border-bottom: ${({active}) =>
    active ? '3px solid var(--text-color-1)' : ''};
  height: 40px;
`;
