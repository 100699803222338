import React from 'react';
import Setting from './Setting';
import Slider from './Slider';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex w-full items-center justify-between">
            <div>
              <h2 className="text-base m-0 font-bold">決算時期</h2>
              <p className="m-0 text-sm">
                重要度を高める決算時期を選択してください。
              </p>
            </div>
            <Slider />
          </div>
          {[1, 2, 3, 4, 5].map((priority) => (
            <Setting priority={priority} />
          ))}
        </div>
      </div>
    </div>
  );
};
