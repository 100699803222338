import {
  useAccountPoolQuery,
  useAccountScoreSettingQuery,
  useCurrentClientQuery,
  useCurrentUserQuery,
} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';
import ScoreItem from './ScoreItem';
import {scoreColor, scoreColorForeground} from 'helpers/scoreColors';

export default () => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {score = null, account = null} = {}} = {}} =
    useAccountPoolQuery({
      variables: {uuid: accountId},
      skip: !accountId,
    });
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});
  const {data: {currentClient: {currentScoreContract = {}} = {}} = {}} =
    useCurrentClientQuery();

  if (currentUser?.role !== 'admin' && !currentScoreContract?.isActive) {
    return null;
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="text-c-light">企業スコア</div>
      <div>
        <span
          className="font-bold text-lg rounded-sm h-6 w-6 flex items-center justify-center"
          style={{
            backgroundColor: scoreColor(score?.scoreRank),
            color: scoreColorForeground(score?.scoreRank),
          }}>
          {score?.scoreRank}
        </span>
      </div>
      <div className="grid grid-cols-2 gap-12">
        <div>
          <ScoreItem
            title="業種"
            score={Math.round(score?.subCategoriesScore * 0.6)}
            target={
              !!accountScoreSetting?.weightSubCategory &&
              account?.company?.subCategories?.length > 0
            }
            hasSetting={accountScoreSetting?.subCategories?.length > 0}
          />
          <ScoreItem
            title="決算"
            score={Math.round(score?.accountClosingMonthScore * 0.6)}
            target={
              !!accountScoreSetting?.weightAccountClosingMonth &&
              !!account?.accountClosingMonth
            }
            hasSetting={
              accountScoreSetting?.clientAccountScoreSettingAccountClosingMonths
                ?.length > 0
            }
          />
          <ScoreItem
            title="従業員数"
            score={score?.employeeRangeScore}
            target={
              !!accountScoreSetting?.weightEmployeeRange &&
              !!account?.employeeNumber
            }
            hasSetting={accountScoreSetting?.employeeRanges?.length > 0}
          />
          <ScoreItem
            title="資本金"
            score={score?.capitalFundRangeScore}
            target={
              !!accountScoreSetting?.weightCapitalFundRange &&
              !!account?.capitalFund
            }
            hasSetting={accountScoreSetting?.capitalFundRanges?.length > 0}
          />
        </div>
        <div>
          {currentUser.role === 'admin' && (
            <ScoreItem title="求人・メディア" score={2} />
          )}
        </div>
      </div>
    </div>
  );
};
