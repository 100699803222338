import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import useFilterModalContext from '../../useFilterModalContext';
import useSearchParams from '../../../../useSearchParams';
import LeadSource from './LeadSource';
import OriginalLeadSource from './OriginalLeadSource';
import Stage from './Stage';
import Unsubscribe from './Unsubscribe';
import Tag from './Tag';
import ExcludedTag from './ExcludedTag';
import Assignee from './Assignee';
import Status from './Status';
import CustomizeItem from './CustomizeItem';
import InflowDate from './InflowDate';
import {useCurrentClientQuery, useCurrentUserQuery} from 'api';
import Bounce from './Bounce';
import PresenceTelNumber from './PresenceTelNumber';
import Phase from './Phase';
import ProspectScore from './ProspectScore';

type Option = {
  label: string;
  value: string;
  isUnselected: boolean;
};

const LeadFilters = () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const {data: {currentClient: {currentScoreContract = {}} = {}} = {}} =
    useCurrentClientQuery();
  const {setFilterType} = useFilterModalContext();
  const {query} = useSearchParams();

  const options: Option[] = [
    {
      label: '最新リードソース',
      value: 'leadSource',
      isUnselected: !query?.leadSourceIds || !query?.leadSourceIds.length,
    },
    {
      label: '初回リードソース',
      value: 'originalLeadSource',
      isUnselected:
        !query?.originalLeadSourceIds || !query.originalLeadSourceIds.length,
    },
    {
      label: 'ステージ',
      value: 'stage',
      isUnselected: !query?.stages || !query.stages.length,
    },
    {
      label: 'フェーズ',
      value: 'phase',
      isUnselected: !query?.phases || !query.phases.length,
    },
    {
      label: '流入日',
      value: 'inflowDate',
      isUnselected: !query.inflowDateFrom && !query.inflowDateTo,
    },
    {
      label: '配信停止',
      value: 'unsubscribe',
      isUnselected: query?.isUnsubscribed === null,
    },
    {
      label: 'タグ',
      value: 'tag',
      isUnselected: !query?.tagIds || !query.tagIds.length,
    },
    {
      label: 'タグ（含まない）',
      value: 'excludedTag',
      isUnselected: !query?.excludedTagIds || !query.excludedTagIds.length,
    },
    {
      label: 'リード担当者',
      value: 'assignee',
      isUnselected: !query?.assignees || !query.assignees.length,
    },
    ...(currentUser?.role === 'admin'
      ? [
          {
            label: '有効リード',
            value: 'status',
            isUnselected: !query?.statuses || !query.statuses.length,
          },
        ]
      : []),
    ...(currentUser?.role === 'admin' || currentScoreContract?.isActive
      ? [
          {
            label: '顧客スコア',
            value: 'prospectScore',
            isUnselected:
              !query?.prospectScore?.from && !query?.prospectScore?.to,
          },
        ]
      : []),
    {
      label: 'カスタマイズ項目',
      value: 'customizeItem',
      isUnselected:
        !query?.prospectCustomizeItemSearchConditions ||
        !query.prospectCustomizeItemSearchConditions.length,
    },
    {
      label: '電話番号ありなし',
      value: 'presenceTelNumber',
      isUnselected: query?.presenceTelNumber === null,
    },
    {
      label: 'バウンスメール',
      value: 'bounce',
      isUnselected: query?.isBounced === null,
    },
  ];

  const unselectedOptions = options.filter((option) => option.isUnselected);

  return (
    <div className="p-6 bg-white rounded flex items-start gap-4">
      <h3 className="m-0 font-bold text-base w-20">リード</h3>
      <div className="flex-1 flex flex-col gap-4">
        <LeadSource />
        <OriginalLeadSource />
        <Stage />
        <Phase />
        <InflowDate />
        <Unsubscribe />
        <Tag />
        <ExcludedTag />
        <Assignee />
        <Status />
        <CustomizeItem />
        <PresenceTelNumber />
        <Bounce />
        <ProspectScore />
        <Menu as="div" className="relative text-left inline-block">
          <Menu.Button className="cursor-pointer bg-white border border-c-border rounded text-sm flex items-center justify-center h-7 px-4">
            + 検索条件を追加
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute left-0 border border-c-border w-[10rem] rounded bg-white shadow-lg focus:outline-none z-10">
              <div className="px-1 py-1 ">
                {unselectedOptions.map((option, index) => (
                  <Menu.Item key={index}>
                    <button
                      onClick={() => setFilterType(option.value)}
                      className="cursor-pointer bg-white text-left p-2 w-full hover:bg-[#F1F8FF] hover:text-c-primary">
                      {option.label}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default LeadFilters;
