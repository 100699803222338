const customTheme = {
  status: {
    project: {
      background: {
        open: 'var(--label08)',
        archived: 'var(--label05)',
        draft: 'var(--gray-100)',
      },
      fontColor: {
        open: 'var(--label08-foreground)',
        archived: 'var(--label05-foreground)',
        draft: 'var(--gray-700)',
      },
    },
    prelead: {
      pending: '',
      approaching: 'var(--blue-300)',
      appoint: 'var(--score-green-foreground)',
      lead: 'var(--score-green-foreground)',
      webinar: 'var(--score-green-foreground)',
      download: 'var(--score-green-foreground)',
      interview: 'var(--score-green-foreground)',
      recyclelead: 'var(--score-green-foreground)',
      inquiry: 'var(--blue-500)',
      refusal: 'var(--gray-300)',
      block: 'var(--red-500)',
      prospect: 'var(--label09)',
    },
    response: {
      no_response: '',
      reply: 'var(--blue-300)',
      inquiry: 'var(--blue-300)',
      appoint: 'var(--score-green-foreground)',
      refusal: 'var(--red-500)',
    },
    tel: {
      pending: '',
      calling: 'var(--gray-300)',
      request_callback: 'var(--gray-300)',
      answering_machine: 'var(--gray-300)',
      unreachable: 'var(--gray-300)',
      called: 'var(--gray-300)',
      no_solicitors: 'var(--blue-300)',
      sending_documents: 'var(--blue-300)',
      no_call: 'var(--blue-300)',
      absence: 'var(--blue-300)',
      done_no_appointment: 'var(--score-green-foreground)',
      done_appointment: 'var(--score-green-foreground)',
      done_reschedule: 'var(--score-green-foreground)',
      done_sent_documents: 'var(--score-green-foreground)',
    },
    mail: {
      unsent: '',
      sent: 'var(--purple-400)',
      mail: 'var(--purple-400)',
      cannotsend: 'var(--gray-300)',
      cannotread: 'var(--gray-300)',
      invalid: 'var(--gray-300)',
      shortage: 'var(--gray-300)',
      notfound: 'var(--gray-300)',
      charover: 'var(--gray-300)',
      recruit: 'var(--gray-300)',
      cannotaccept: 'var(--gray-300)',
      unknown: 'var(--gray-300)',
      duplicate: 'var(--gray-300)',
      block: 'var(--gray-300)',
      nourl: 'var(--gray-300)',
    },
    preleadCategory: {
      pending: '',
      approaching: 'var(--blue-300)',
      appoint: 'var(--score-green-foreground)',
      inquiry: 'var(--blue-500)',
      refusal: 'var(--gray-300)',
      block: 'var(--red-500)',
      prospect: 'var(--label09)',
    },
    telCategory: {
      call: '#bdc4cb', // <- var(--gray-300)
      reception: '#66D7EF', // <- var(--blue-300),
      contact: '#2d9cdb', // <- var(--blue-500),
      appoint: '#27ae60', // <- var(--score-green-foreground),
      document: '#8966EF', // <- var(--purple-400),
      other: '#3E3A39', // <- var(--gray-800)
      call_ng: '#FFC75A', // <- var(--label09),
      reception_ng: '#FF8F00', // <- var(--label10),
      contact_ng: '#EB5757', // <- var(--red-500),
    },
    letter: {
      unsent: '',
      sent: 'var(--score-green-foreground)',
    },
  },
};

export default customTheme;
