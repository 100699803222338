import React from 'react';
import Category from './Category';
import UpdateScoreButton from './UpdateScoreButton';
import Employee from './Employee';
import AccountClosingMonth from './AccountClosingMonth';
import CapitalFund from './CapitalFund';
import JobOfferMedia from './JobOfferMedia';

export default () => {
  return (
    <div className="flex flex-col gap-4">
      <UpdateScoreButton />
      <Category />
      <Employee />
      <CapitalFund />
      <AccountClosingMonth />
      <JobOfferMedia />
    </div>
  );
};
