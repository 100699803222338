import React from 'react';
import Table from './Table';
import Count from './Count';

export default () => {
  return (
    <div>
      <div className="flex justify-end items-end mb-2">
        <div className="flex items-end gap-4">
          <Count />
        </div>
      </div>
      <Table />
    </div>
  );
};
