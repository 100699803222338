import React from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {Button} from 'components/antd';
import {useCurrentUserQuery} from 'api';

export default () => {
  const history = useHistory();
  const {poolId} = useParams<{
    poolId: string;
  }>();

  const {data: {currentUser = null} = {}} = useCurrentUserQuery();

  const active = 'font-bold text-foreground bg-white';
  const inActive =
    'hover:text-foreground hover:bg-[#FAFBFB] text-foreground-lighter';

  return (
    <div className="flex">
      <div className="flex border-none bg-[#EBEDF2] p-1 cursor-pointer gap-1">
        <Link to={`/pools/${poolId}/prospects`}>
          <div
            className={`flex rounded-sm px-6 py-1 ${history.location.pathname.startsWith(`/pools/${poolId}/prospects`) ? active : inActive}`}>
            リード
          </div>
        </Link>
        <Link to={`/pools/${poolId}/accounts`}>
          <div
            className={`flex rounded-sm px-6 py-1 ${history.location.pathname.startsWith(`/pools/${poolId}/accounts`) ? active : inActive}`}>
            企業
          </div>
        </Link>
        <Link to={`/pools/${poolId}/account_groups`}>
          <div
            className={`flex rounded-sm px-6 py-1 ${history.location.pathname.startsWith(`/pools/${poolId}/account_groups`) ? active : inActive}`}>
            リスト
          </div>
        </Link>
      </div>
    </div>
  );
};
