import React from 'react';
import useInterval from 'beautiful-react-hooks/useInterval';
import {useLatestMaintenanceRefreshQuery} from 'api';

export default () => {
  const {data = {}, refetch: refetchLatestMaintenanceRefresh} =
    useLatestMaintenanceRefreshQuery({
      fetchPolicy: 'cache-and-network',
    });

  const maintenanceRefresh = data?.latestMaintenanceRefresh;

  const total = maintenanceRefresh?.prospectPoolsCount;
  const current = maintenanceRefresh?.currentCount;
  const percent = (current / total) * 100 || 0;

  useInterval(() => {
    refetchLatestMaintenanceRefresh();
  }, 3000);

  return (
    <div className="w-48">
      <div className="mb-1 text-sm font-bold text-c-purple">{`${current}件 重複判定処理中`}</div>
      <div className="w-full bg-c-bg rounded-full h-1.5">
        <div
          className="bg-c-purple h-1.5 rounded-full"
          style={{width: `${percent || 0}%`}}
        />
      </div>
    </div>
  );
};
