import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const PresenceTelNumberFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <>
      <Header title="電話番号ありなし" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio"
              checked={query.presenceTelNumber === true}
              onChange={() => setQuery({...query, presenceTelNumber: true})}
            />
            <span className="ml-2">あり</span>
          </label>
          <label className="flex items-center text-base">
            <input
              type="radio"
              className="form-radio"
              checked={query.presenceTelNumber === false}
              onChange={() => setQuery({...query, presenceTelNumber: false})}
            />
            <span className="ml-2">なし</span>
          </label>
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, presenceTelNumber: null})} />
    </>
  );
};

export default PresenceTelNumberFilter;
