import {AccountKeyPerson, KeyPerson} from 'api';
import React from 'react';
import {Row} from 'react-table';
import BasicInformation from './BasicInformation';

interface Props {
  row: Row<KeyPerson>;
}
export default ({row}: Props) => {
  const keyPerson = row.original;
  const accountKeyPerson: AccountKeyPerson = keyPerson.accountKeyPerson;
  return (
    <div className="flex flex-col gap-2">
      {accountKeyPerson ? (
        <BasicInformation
          lastName={accountKeyPerson.lastName}
          firstName={accountKeyPerson.firstName}
          department={accountKeyPerson.department}
          position={accountKeyPerson.position}
        />
      ) : (
        <BasicInformation
          lastName={keyPerson.lastName}
          firstName={keyPerson.firstName}
          department={keyPerson.department}
          position={keyPerson.position}
        />
      )}
    </div>
  );
};
