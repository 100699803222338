import React from 'react';
import {useParams} from 'react-router-dom';
import {useTable} from 'react-table';
import styled from 'styled-components';
import {Pagination as UIPagination} from 'components/antd';
import Cell from './Cell';
import {useActionsQuery, Action} from 'api';
import useSearchParams from './useSearchParams';
import {useRecoilState} from 'recoil';
import checkedActionIdsAtom from './checkedActionIdsAtom';
import Header from './Header';

export default () => {
  const {poolId} = useParams<{poolId: string}>();
  const {page, perPage, setQuery, searchParams} = useSearchParams();
  const {data: {actions: {actions = [], pagination = {}} = {}} = {}} =
    useActionsQuery({
      variables: {
        uuid: poolId,
        search: searchParams,
        page,
        perPage,
      },
      fetchPolicy: 'cache-and-network',
    });

  const columns: any = React.useMemo(
    () => [
      {
        id: 'check',
        Header: Header.Check,
        Cell: Cell.Check,
        style: {
          width: '40px',
        },
        cellStyle: {
          textOverflow: 'inherit',
        },
      },
      {
        id: 'icon',
        Header: '',
        Cell: Cell.Icon,
        style: {
          width: '72px',
        },
      },
      {
        id: 'prospect',
        Header: 'リード',
        Cell: Cell.Name,
      },
      {
        id: 'stage',
        Header: 'ステージ',
        Cell: Cell.Stage,
      },
      {
        id: 'workflow',
        Header: 'ワークフロー',
        Cell: Cell.Workflow,
      },
      {
        id: 'date',
        Header: '日付',
        Cell: Cell.ActionDate,
        style: {
          width: '140px',
        },
      },
      {
        id: 'LastProspectActivity',
        Header: '履歴',
        Cell: Cell.LastProspectActivity,
      },
      {
        id: 'assignee',
        Header: 'ワークフロー担当者',
        Cell: Cell.Assignee,
        style: {
          width: '160px',
        },
      },
      {
        id: 'menu',
        Header: '',
        Cell: Cell.Menu,
        style: {
          width: '80px',
        },
      },
    ],
    [],
  );

  const [checkedActionIds] = useRecoilState(checkedActionIdsAtom);

  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable<Action>({columns, data: actions || []});

  return (
    <Container>
      <ContentsTable {...getTableProps()} cellSpacing="0" cellPadding="0">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, j) => {
                return (
                  <th
                    {...column.getHeaderProps([
                      {
                        // className: column.className,
                        // @ts-ignore
                        style: column.style,
                      },
                    ])}
                    key={j}>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <CustomTr
                {...row.getRowProps()}
                key={i}
                active={checkedActionIds.find(
                  (uuid) => uuid === row.original.uuid,
                )}>
                {row.cells.map((cell, j) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          // className: cell.column.className,
                          // @ts-ignore
                          style: cell.column.cellStyle,
                        },
                      ])}
                      key={j}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </CustomTr>
            );
          })}
        </tbody>
      </ContentsTable>
      <Pagination
        size="small"
        showSizeChanger={false}
        showQuickJumper={false}
        total={pagination.totalCount}
        current={pagination.currentPage}
        pageSize={perPage}
        onChange={(page) => setQuery({page})}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentsTable = styled.table`
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  table-layout: fixed;

  td {
    min-width: 50px;
    padding: 0.5rem 1rem;
    margin: 0px;
  }

  thead {
    tr {
      th {
        padding: 8px 1rem;
        font-size: 10px;
        color: var(--text-color-2);
        text-align: left;
        border-bottom: solid 1px #e1e6eb;
      }
    }
  }

  tbody {
    font-size: 13px;
  }
`;

const CustomTr = styled.tr<{active?: any}>`
  box-sizing: border-box;
  background: ${({active}) => (active ? '#F8FBFF' : '#FFF')};

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: solid 1px #e1e6eb;
  }
`;

const Pagination = styled(UIPagination)`
  display: inline-block;
  padding: 20px 0;
  height: 65px;
  border-radius: 5px;

  li {
    width: 30px !important;
    height: 30px !important;

    a {
      width: 100%;
      height: 100%;
      line-height: 30px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .ant-pagination-item-active {
    border: none;
    background: none;

    a {
      color: var(--text-color-1);
      background: var(--border-color);
      border-radius: 100px;
    }
  }
`;
