import gql from 'graphql-tag';

export default gql`
  fragment prospectPoolScoreFragment on ProspectPoolScore {
    id
    positionScore
    sectionScore
    leadSourceScore
    totalScore
    latestInflowDateScore
    scoreRank
    tagScore
    mailOpenedOrClickedAtScore
    lostAtScore
    trackedAtScore
  }
`;
