import React from 'react';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {useMailCampaignSendsQuery, MailCampaignSend, MailCampaign} from 'api';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import Pagination from './Pagination';
import Prospect from 'components/Prospect';

const PER_PAGE = 10;

const columnHelper = createColumnHelper<MailCampaignSend>();

interface Props {
  mailCampaign: MailCampaign;
}

export default ({mailCampaign}: Props) => {
  const {mailCampaignId} = useParams<{
    mailCampaignId: string;
  }>();

  const [selectedMailCampaignSend, setSelectedMailCampaignSend] =
    React.useState<MailCampaignSend | null>(null);

  const [page, setPage] = React.useState(1);

  const columns = [
    columnHelper.accessor('to', {
      header: () => 'メールアドレス',
      cell: ({getValue}) => getValue(),
    }),
    columnHelper.accessor('prospectPool', {
      id: 'prospect',
      header: () => 'リード名',
      cell: (info) => (
        <span
          className="cursor-pointer text-c-primary"
          onClick={() => setSelectedMailCampaignSend(info.row.original)}>
          {[
            info.getValue().prospect.lastName,
            info.getValue().prospect.firstName,
          ]
            .filter(Boolean)
            .join(' ')}
        </span>
      ),
    }),
    columnHelper.accessor('prospectPool', {
      id: 'account',
      header: () => '企業名',
      cell: ({getValue}) => getValue().prospect.account.name,
    }),
    columnHelper.accessor('prospectActivityMailCampaignMailClicks', {
      id: 'latestClickDate',
      header: () => '最新クリック日時',
      cell: ({getValue}) =>
        getValue().length
          ? moment(getValue()[getValue().length - 1].createdAt).format(
              'YYYY/MM/DD HH:mm',
            )
          : '',
    }),
    columnHelper.accessor('firstOpenedAt', {
      id: 'firstOpenDate',
      header: () => '初回開封日時',
      cell: ({getValue}) =>
        getValue() ? moment(getValue()).format('YYYY/MM/DD HH:mm') : '',
    }),
    columnHelper.accessor('activityMailCampaignMailClickCount', {
      id: 'clickCount',
      header: () => 'クリック回数',
      cell: ({getValue}) => getValue().toLocaleString(),
    }),
  ];

  const {
    data: {
      mailCampaignSends: {mailCampaignSends = [], pagination = {}} = {},
    } = {},
  } = useMailCampaignSendsQuery({
    variables: {
      mailCampaignUuid: mailCampaignId,
      page: page || 1,
      perPage: PER_PAGE,
    },
    fetchPolicy: 'cache-and-network',
    skip: !mailCampaignId,
  });

  const table = useReactTable({
    data: mailCampaignSends,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    autoResetExpanded: false,
  });

  return (
    <>
      <table className="w-full mb-4">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="text-left border-b border-b-c-border text-c-light text-sm py-1"
                  style={{width: `${header.getSize()}px`}}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-b-c-border min-h-12 py-2 align-middle text-sm">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        pageSize={PER_PAGE}
        current={pagination.currentPage || 1}
        total={pagination.totalCount || 0}
        onChange={(page) => setPage(page)}
      />
      <Prospect
        prospectId={selectedMailCampaignSend?.prospectPool?.uuid}
        poolId={mailCampaign.pool.uuid}
        onClose={() => setSelectedMailCampaignSend(null)}
      />
    </>
  );
};
