import React from 'react';
import {useParams} from 'react-router-dom';
import {useRecoilState} from 'recoil';
import checkedPreleadProjectsAtom from '../checkedPreleadProjectsAtom';
import {usePreleadProjectsQuery} from 'api';
import useSearchParams from '../useSearchParams';

const CheckHeader = () => {
  const {projectId} = useParams<{
    projectId: string;
  }>();

  const {searchParams, page, perPage} = useSearchParams();

  const [checkedPreleadProjects, setCheckedPreleadProjects] = useRecoilState(
    checkedPreleadProjectsAtom,
  );

  const {data: {preleadProjects: {preleadProjects = []} = {}} = {}} =
    usePreleadProjectsQuery({
      variables: {
        uuid: projectId,
        search: searchParams,
        perPage,
        page,
      },
      fetchPolicy: 'cache-only',
    });

  const preleadProjectIds = React.useMemo(
    () => preleadProjects.map((preleadProject) => preleadProject.uuid),
    [preleadProjects],
  );

  const checked = React.useMemo(
    () => preleadProjectIds.every((id) => checkedPreleadProjects.includes(id)),
    [checkedPreleadProjects, preleadProjectIds],
  );

  const handleChange = React.useCallback(
    (e: any) => {
      if (e.target.checked) {
        setCheckedPreleadProjects(preleadProjectIds);
      } else {
        setCheckedPreleadProjects([]);
      }
    },
    [preleadProjectIds],
  );

  return (
    <input
      type="checkbox"
      className="form-checkbox"
      checked={checked}
      onChange={handleChange}
    />
  );
};

export default CheckHeader;
