import gql from 'graphql-tag';

export default gql`
  fragment prospectPoolFragment on ProspectPool {
    id
    uuid
    stage
    stageText
    rank
    rankText
    isUnsubscribed
    createdAt
    status
    trackedAt
    salesforceContactId
    salesforceLeadId
    workflowProspectPoolId
    isBounced
    originalInflowDate
    latestInflowDate
    mailCampaignClickedAt
    mailCampaignOpenedAt
    workflowMailClickedDate
    workflowMailOpenedDate
    telUpdatedDate
    mailOpenedOrClickedAt
    lostAt
    totalScore
    scoreRank
  }
`;
