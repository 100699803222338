import React from 'react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  if (currentUser?.role !== 'admin') {
    return null;
  }
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div>
            <h2 className="text-base m-0 font-bold">求人・メディア</h2>
            <p className="m-0 text-sm">
              選択した求人・メディア種別の重要度を高めてスコアを算出します。また、重視するキーワードを設定できます。
            </p>
          </div>
          <div className="flex flex-col gap-2 text-sm">
            <label className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border"
              />
              <span className="ml-2">新卒採用</span>
            </label>
            <label className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border"
              />
              <span className="ml-2">中途採用</span>
            </label>
            <label className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border"
              />
              <span className="ml-2">契約・派遣社員</span>
            </label>
            <label className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border"
              />
              <span className="ml-2">パート・アルバイト</span>
            </label>
            <label className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border"
              />
              <span className="ml-2">プレスリリース</span>
            </label>
            <label className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border"
              />
              <span className="ml-2">BOXIL</span>
            </label>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h3 className="text-sm font-bold m-0">重要キーワード設定</h3>
          <div className="p-2 border border-c-border rounded-sm flex gap-2">
            <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
              第二新卒 <XMarkIcon className="h-4 w-4" />
            </span>
            <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
              プロジェクトマネジメント
              <XMarkIcon className="h-4 w-4" />
            </span>
            <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
              インフラエンジニア <XMarkIcon className="h-4 w-4" />
            </span>
            <span className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1">
              急募 <XMarkIcon className="h-4 w-4" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
