import React from 'react';
import Position from './Position';
import LeadSource from './LeadSource';
import UpdateScoreButton from './UpdateScoreButton';
import InflowSeason from './InflowSeason';
import ProspectTag from './ProspectTag';
import Section from './Section';
import MailOpenedOrClickedAt from './MailOpenedOrClickedAt';
import LostAt from './LostAt';
import TrackedAt from './TrackedAt';

export default () => {
  return (
    <div className="flex flex-col gap-4">
      <UpdateScoreButton />
      <Position />
      <Section />
      <LeadSource />
      <ProspectTag />
      <InflowSeason />
      <MailOpenedOrClickedAt />
      <TrackedAt />
      <LostAt />
    </div>
  );
};
