import React from 'react';
import CheckboxGroup from './CheckboxGroup';
import {
  useProspectTagsQuery,
  ProspectTag,
  useProspectScoreSettingQuery,
  useCreateClientProspectScoreSettingProspectTagMutation,
  useDestroyClientProspectScoreSettingProspectTagMutation,
} from 'api';

interface Props {
  priority: number;
}

export default ({priority}: Props) => {
  const [create] = useCreateClientProspectScoreSettingProspectTagMutation({});
  const [destroy] = useDestroyClientProspectScoreSettingProspectTagMutation({});

  const {data: {prospectTags = []} = {}} = useProspectTagsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );

  const optionProspectTags = [
    ...prospectTags.map((prospectTag) => ({
      text: prospectTag.name,
      value: prospectTag.id,
      keyword: prospectTag.name,
      color: prospectTag.color,
    })),
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    if (checked) {
      create({
        variables: {
          priority,
          prospectTagId: value,
        },
      });
    } else {
      destroy({
        variables: {
          priority,
          prospectTagId: value,
        },
      });
    }
  };

  return (
    <CheckboxGroup
      filter
      options={optionProspectTags}
      priority={priority}
      allSelected={prospectScoreSetting?.prospectTags?.map((tag) => tag?.id)}
      values={prospectScoreSetting?.prospectTagsGroupByPriority?.[
        priority
      ]?.map((tag: ProspectTag) => String(tag.id))}
      handleChange={handleChange}
    />
  );
};
