import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const TrackedAtFilter = () => {
  const {query, setQuery} = useSearchParams();

  const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({...query, inflowDateFrom: e.target.value});

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({...query, inflowDateTo: e.target.value});

  const onChangeType = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({...query, inflowDateType: e.target.value});

  return (
    <>
      <Header title="流入日" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <div className="flex flex-col mb-6 border-b border-[#E1E6EB] pb-5 gap-2">
            <div className="flex items-center gap-1">
              <input
                type="radio"
                value="latest"
                name="type"
                id="latest"
                onChange={onChangeType}
                checked={query.inflowDateType === 'latest'}
                className="form-radio"
              />
              <label htmlFor="latest">最新</label>
            </div>
            <div className="flex items-center gap-1">
              <input
                type="radio"
                value="original"
                name="type"
                id="original"
                onChange={onChangeType}
                checked={query.inflowDateType === 'original'}
                className="form-radio"
              />
              <label htmlFor="original">初回</label>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <input
              type="date"
              value={query.inflowDateFrom ?? ''}
              max={query.inflowDateTo}
              onChange={onChangeFrom}
              className="form-input p-2 text-sm leading-normal"
            />
            <span>~</span>
            <input
              type="date"
              min={query.inflowDateFrom}
              value={query.inflowDateTo ?? ''}
              onChange={onChangeTo}
              className="form-input p-2 text-sm leading-normal"
            />
          </div>
        </div>
      </div>
      <Footer
        onClear={() =>
          setQuery({
            ...query,
            inflowDateFrom: null,
            inflowDateTo: null,
            inflowDateType: 'latest',
          })
        }
      />
    </>
  );
};

export default TrackedAtFilter;
