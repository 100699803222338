import gql from 'graphql-tag';

export default gql`
  fragment accountKeyPersonFragment on AccountKeyPerson {
    id
    department
    firstName
    lastName
    position
    updatedAt
    facebookUrl
    xUrl
    linkedinUrl
    wantedlyUrl
    eightUrl
    telephoneNumber
    note
  }
`;
