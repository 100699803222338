import React from 'react';
import styled from 'styled-components';
import {useParams, useHistory} from 'react-router-dom';
import {Content} from 'api';
import Item from './Item';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {id, version} = useParams<{id: string; version: string}>();
  const history = useHistory();

  const editingVersion = React.useMemo(
    () =>
      Math.max(
        ...content?.mailForm?.versions?.map((version) => version.version),
        0,
      ) + 1,
    [content],
  );

  return (
    <>
      <h4>バージョン履歴</h4>
      <Latest
        active={!version}
        onClick={() =>
          history.push(`/garage/contents/${id}/mail_form/${location.search}`)
        }>
        <Version active={!version}>V{editingVersion}</Version>
        <Content>
          <h3>{content?.mailForm?.title}</h3>
          未確定
        </Content>
      </Latest>
      {content?.mailForm?.versions.map((mailFormVersion) => (
        <Item key={mailFormVersion.id} mailFormVersion={mailFormVersion} />
      ))}
    </>
  );
};

const Latest = styled.div<{active?: boolean}>`
  display: flex;
  padding: 1rem;
  border-top: solid 1px #e1e6eb;
  cursor: pointer;

  background-color: ${({active}) => (active ? '#f8fbff' : '#fff')};

  &:hover {
    background: #f8fbff;
  }
`;

const Version = styled.span<{active?: boolean}>`
  background-color: ${({active}) =>
    active ? 'var(--label09)' : 'var(--text-color-3)'};
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  border-radius: 4px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
`;

const Content = styled.div`
  margin-left: 1rem;
  color: var(--text-color-1);
  flex: 1;

  h3 {
    color: var(--text-color-1);
    line-height: 20px;
    font-weight: bold;
    margin-bottom: 3px;
  }
`;
