import React from 'react';
import Header from '../Header';
import Footer from './Footer';
import useSearchParams from '../../../useSearchParams';

const EstablishedDateFilter = () => {
  const {query, setQuery} = useSearchParams();

  const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({
      ...query,
      establishedDate: {...query.establishedDate, from: e.target.value},
    });

  const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) =>
    setQuery({
      ...query,
      establishedDate: {...query.establishedDate, to: e.target.value},
    });

  return (
    <>
      <Header title="設立年月" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          <div className="text-c-base font-bold">設立年月</div>
          <div className="flex items-center">
            <input
              min={1}
              type="month"
              value={query?.establishedDate?.from ?? ''}
              onChange={onChangeFrom}
              className="form-input w-28 p-2 text-sm"
            />
            <span className="mx-2">~</span>
            <input
              min={1}
              type="month"
              value={query?.establishedDate?.to ?? ''}
              onChange={onChangeTo}
              className="form-input w-28 p-2 text-sm"
            />
          </div>
        </div>
      </div>
      <Footer
        onClear={() => {
          setQuery({
            establishedDate: {
              from: null,
              to: null,
            },
          });
        }}
      />
    </>
  );
};

export default EstablishedDateFilter;
