import React from 'react';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';

interface Props {
  options: {value: string; text: string; keyword?: string}[];
  values: string[];
  onChange: (values: string[]) => void;
  filter?: boolean;
}

const CheckboxGroup = ({options, values, onChange, filter}: Props) => {
  const [keyword, setKeyword] = React.useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    if (checked) {
      onChange([...values, value]);
    } else {
      onChange(values.filter((v) => v !== value));
    }
  };

  const filteredOptions =
    keyword === ''
      ? options
      : options.filter((option) =>
          (option.keyword || option.text)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(keyword.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div className="flex flex-col gap-2">
      {filter && (
        <div className="flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2">
          <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
          <input
            className="h-8 text-sm border-c-border flex-1 outline-none"
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
      )}
      {filteredOptions.map((option) => (
        <label key={option.value} className="flex items-center text-base">
          <input
            type="checkbox"
            value={option.value}
            className="form-checkbox"
            checked={values?.includes(option.value)}
            onChange={handleChange}
          />
          <span className="ml-2">{option.text}</span>
        </label>
      ))}
    </div>
  );
};

export default CheckboxGroup;
