import React from 'react';
import {MagnifyingGlassIcon} from '@heroicons/react/24/outline';

interface Props {
  options: {value: string; text: string; keyword?: string; color?: string}[];
  priority: number;
  values: string[];
  allSelected: string[];
  filter?: boolean;
  disabled?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxGroup = ({
  options,
  values,
  filter,
  allSelected,
  disabled,
  handleChange,
}: Props) => {
  const [keyword, setKeyword] = React.useState<string>('');

  const filteredOptions =
    keyword === ''
      ? options
      : options.filter((option) =>
          (option.keyword || option.text)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(keyword.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <div className="flex flex-col gap-6">
      {filter && (
        <div
          className={`flex items-center gap-2 rounded h-9 text-sm border border-c-border px-2 ${
            disabled && 'bg-c-bg'
          }`}>
          <MagnifyingGlassIcon className="w-4 h-4 text-c-lighter" />
          <input
            className="h-8 text-sm border-c-border flex-1 outline-none"
            onChange={(e) => setKeyword(e.target.value)}
            disabled={disabled}
          />
        </div>
      )}
      <div className="h-60 overflow-y-scroll px-1 gap-2">
        <h1 className="text-xs font-bold">検索対象のタグ</h1>
        {filteredOptions.map((option) => (
          <label
            key={option.value}
            className="flex items-center text-base cursor-pointer">
            <input
              type="checkbox"
              value={option.value}
              className="form-checkbox h-5 w-5 rounded border-c-border disabled:bg-c-bg disabled:text-c-light cursor-pointer"
              checked={values?.includes(option.value)}
              onChange={handleChange}
              disabled={
                !values?.includes(option.value) &&
                allSelected?.includes(option.value)
              }
            />
            <div className="flex justify-between items-center gap-2 flex-shrink-0">
              <div
                className="w-4 h-4 ml-2"
                style={{backgroundColor: option.color}}></div>
              <div className="ml-2">{option.text}</div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
