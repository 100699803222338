import React from 'react';
import styled from 'styled-components';
import useInterval from 'beautiful-react-hooks/useInterval';
import {Battery} from 'components/Ui/Icon';

type Props = {
  percent: number;
  refetch: (variables?: any) => Promise<any>;
};

export default ({percent, refetch}: Props) => {
  useInterval(() => {
    refetch();
  }, 3000);

  return (
    <Container>
      <Meta>
        <Battery />
        <Status>処理中</Status>
        <ProgressNumber>{percent}%</ProgressNumber>
      </Meta>
      <ProgressbarWrapper>
        <Progressbar width={percent} color="#F8D55C" />
      </ProgressbarWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  * {
    margin: auto 0;
  }
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
`;

const Status = styled.div`
  margin-left: 2px;
  width: 60px;
  font-weight: bold;
  font-size: 12px;
  color: #8966ef;
`;

const ProgressNumber = styled.span`
  margin-left: auto;
  font-weight: bold;
  font-size: 12px;
  color: var(--text-color-3);
`;

const ProgressbarWrapper = styled.span`
  margin-top: 5px;
  position: relative;
  height: 6px;
  background: var(--border-color);
  border-radius: 10px;
`;

const Progressbar = styled.span<{
  width: number;
}>`
  position: absolute;
  width: ${({width}) => `${width}%`};
  height: 6px;
  background: #8966ef;
  border-radius: 10px;
`;
