import React from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';

const StatusFilter = () => {
  const {query, setQuery} = useSearchParams();
  const options = [
    {label: '有効', value: 'active'},
    {label: 'アーカイブ', value: 'archived'},
    {label: 'クローズ', value: 'closed'},
  ];

  const onChange = (statuses: string[]) => setQuery({...query, statuses});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, value} = e.target;
    if (checked) {
      onChange([...query.statuses, value]);
    } else {
      onChange(query.statuses.filter((v) => v !== value));
    }
  };

  return (
    <>
      <Header title="有効リード" />
      <div className="p-6 bg-c-bg flex-1 flex flex-col gap-4 rounded-b overflow-y-scroll">
        <div className="bg-white p-6 rounded flex flex-col gap-2">
          {options.map((option) => (
            <label key={option.value} className="flex items-center text-base">
              <input
                type="checkbox"
                className="form-checkbox"
                value={option.value}
                checked={query.statuses.includes(option.value)}
                onChange={handleChange}
              />
              <span className="ml-2">{option.label}</span>
            </label>
          ))}
        </div>
      </div>
      <Footer onClear={() => setQuery({...query, statuses: ['active']})} />
    </>
  );
};

export default StatusFilter;
