import React, {useState} from 'react';
import useSearchParams from '../../../useSearchParams';
import Header from '../Header';
import Footer from './Footer';
import {Combobox, Transition} from '@headlessui/react';
import {useUsersByClientAllQuery} from 'api';
import {ChevronUpDownIcon, XMarkIcon} from '@heroicons/react/20/solid';

const kanaToHira = (str: string) =>
  str.replace(/[\u30a1-\u30f6]/g, (match) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });

const LastTelActivityFilter = () => {
  const {query, setQuery} = useSearchParams();
  const {data: {usersByClientAll: {users: allUsers = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {
        search: {
          active: true,
        },
      },
      fetchPolicy: 'cache-and-network',
    });

  const [users, setUsers] = useState(query.lastTelUserIds);
  const [userName, setUserName] = useState('');
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const filteredUsers = React.useMemo(
    () =>
      allUsers
        ?.filter((user) => !users.includes(user.id))
        ?.filter((user) =>
          kanaToHira(`${user.lastName}`).includes(kanaToHira(userName)),
        ),
    [allUsers, userName, users],
  );

  const selectedUsers = React.useMemo(
    () =>
      users
        ?.map((selectedUser) =>
          allUsers.find((allUser) => allUser.id === selectedUser),
        )
        .filter((selectedUser) => selectedUser),
    [users, allUsers],
  );

  return (
    <>
      <Header title="最新コールログ" />
      <div className="px-6 py-4 bg-c-bg flex-1 flex flex-col gap-4 rounded-b">
        <div className="h-full bg-white px-6 py-3 rounded flex flex-col gap-2">
          <div className="mt-2 text-c-base font-bold">コール担当者</div>
          <Combobox
            value={users}
            multiple
            onChange={(users) => {
              setUsers(users);
              setQuery({lastTelUserIds: users});
            }}>
            <div
              className="relative mt-1 h-[2.5rem]"
              onClick={() => inputRef.current?.focus()}>
              <div className="relative w-full min-h-[2.5rem] cursor-default rounded-sm bg-white py-2 pl-2 pr-10 flex items-center gap-1 border border-c-border focus:outline-none flex-wrap">
                {selectedUsers.map((selectedUser) => (
                  <span
                    key={selectedUser.id}
                    className="truncate inline-flex items-center justify-center pl-2 pr-1 py-1 text-sm font-medium leading-4 rounded-sm bg-[#F3F5F7]"
                    onClick={(e) => e.stopPropagation()}>
                    {selectedUser.lastName} {selectedUser.firstName}
                    <XMarkIcon
                      className="w-4 h-4 ml-2 cursor-pointer hover:bg-[rgba(255,255,255,.3)] rounded-sm"
                      onClick={() => {
                        const currentUsers = users.filter(
                          (user) => user !== selectedUser.id,
                        );
                        setUsers(currentUsers);
                        setQuery({
                          lastTelUserIds: currentUsers,
                        });
                      }}
                    />
                  </span>
                ))}
                <Combobox.Input
                  onChange={(e) => setUserName(e.target.value)}
                  onFocus={() => buttonRef.current?.click()}
                  value={userName}
                  ref={inputRef}
                  className="flex-1 outline-none border-transparent focus:border-transparent focus:ring-0"
                />
                <Combobox.Button className="invisible" ref={buttonRef} />
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <Transition
                as={React.Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Combobox.Options className="absolute max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                  {filteredUsers.map((user) => (
                    <Combobox.Option
                      key={user.id}
                      className={({active}) =>
                        `relative cursor-default select-none py-2 px-4 ${
                          active ? 'bg-c-bg' : 'white'
                        }`
                      }
                      value={user.id}>
                      <div className="flex items-center gap-2">
                        <span className="truncate flex-1">
                          {user.lastName} {user.firstName}
                        </span>
                      </div>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
          <div className="flex flex-col border-t border-c-border my-3">
            <div className="mt-2 text-c-base font-bold">期間</div>
            <div>
              <input
                type="date"
                value={query.telUpdatedDateFrom}
                onChange={(e: any) =>
                  setQuery({
                    telUpdatedDateFrom: e.target.value,
                  })
                }
                className="form-input w-32 p-2 text-sm leading-none"
              />

              <span className="mx-2">~</span>
              <input
                type="date"
                value={query.telUpdatedDateTo}
                min={query.telUpdatedDateFrom}
                onChange={(e: any) =>
                  setQuery({
                    telUpdatedDateTo: e.target.value,
                  })
                }
                className="form-input w-32 p-2 text-sm leading-none"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer
        onClear={() => {
          setQuery({
            ...query,
            telUpdatedDateFrom: '',
            telUpdatedDateTo: '',
            lastTelUserIds: [],
          });
          setUsers([]);
        }}
      />
    </>
  );
};

export default LastTelActivityFilter;
