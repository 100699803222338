import React from 'react';
import Input from './Input';
import Slider from './Slider';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex w-full items-center justify-between">
            <div>
              <h2 className="text-base m-0 font-bold">Web訪問時期</h2>
              <p className="m-0 text-sm">
                重要度を高めるWeb訪問時期を選択してください。
              </p>
            </div>
            <Slider />
          </div>
          {[1, 2, 3, 4, 5].map((priority) => {
            return <Input priority={priority} />;
          })}
        </div>
      </div>
    </div>
  );
};
