import React from 'react';
import {
  useProspectScoreSettingQuery,
  useUpdateClientProspectScoreSettingDateRangeMutation,
  ProspectScorePriorityDateRange,
} from 'api';

export default function Input({priority}: {priority: number}) {
  const [update] = useUpdateClientProspectScoreSettingDateRangeMutation({});
  const {data: {prospectScoreSetting = {}} = {}} = useProspectScoreSettingQuery(
    {},
  );
  const selected =
    prospectScoreSetting?.lostAtDateRanges?.find(
      (range) => range.priority === String(priority),
    )?.dateRange ?? '';
  const options = [
    {name: '0~1週間', value: '0to1week'},
    {name: '1週間~1ヶ月', value: '1weekto1month'},
    {name: '1~3ヶ月', value: '1to3months'},
    {name: '3~6ヶ月', value: '3to6months'},
    {name: '6~9ヶ月', value: '6to9months'},
    {name: '9~12ヶ月', value: '9to12months'},
    {name: '1年~2年', value: '1to2years'},
    {name: '2年~', value: '2years'},
  ];
  return (
    <div className="flex items-center gap-2">
      <div className="text-sm font-bold w-12 text-center flex items-center">
        <span>優先度{priority}</span>
      </div>
      <select
        className="form-select border-c-border rounded text-sm h-8 pt-[3px] w-32 cursor-pointer"
        value={selected}
        onChange={(e) => {
          update({
            variables: {
              attributes: {
                dateRange: e.target.value,
                scoreType: 'lost_at',
                priority,
              },
            },
          });
        }}>
        <option value={''}></option>
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={option.value}
              disabled={prospectScoreSetting?.lostAtDateRanges
                ?.map(
                  (range: ProspectScorePriorityDateRange) => range?.dateRange,
                )
                ?.includes(option.value)}>
              {option.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
