import React from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router';
import {Content} from 'api';

interface Props {
  content: Content;
}

export default ({content}: Props) => {
  const {version} = useParams<{version: string}>();

  return (
    <Title>
      <Version>V{version}</Version>
      <h2>{content.title}</h2>
    </Title>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-size: 18px;
    padding: 8px 11px;
    margin: 0;
    font-weight: bold;
  }
`;

const Version = styled.span`
  background-color: var(--label09);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
`;
