import React from 'react';
import MailCampaignSendsTable from './MailCampaignSendsTable';
import UrlsTable from './UrlsTable';
import {MailCampaign} from 'api';
import DownloadModal from './DownloadModal';

interface Props {
  mailCampaign: MailCampaign;
}

export default ({mailCampaign}: Props) => {
  const [type, setType] = React.useState('prospect');
  const [downloadModalVisible, setDownloadModalVisible] = React.useState(false);

  return (
    <div className="mt-6 bg-white">
      <div></div>
      <div className="inline-flex rounded mb-6 w-full" role="group">
        <button
          type="button"
          onClick={() => setType('prospect')}
          className={`cursor-pointer w-32 h-9 text-sm font-bold border-[3px] border-c-bg rounded-l flex items-center justify-center ${
            type === 'prospect' ? 'bg-white text-base' : 'bg-c-bg text-c-light'
          }`}>
          受信者
        </button>
        <button
          type="button"
          onClick={() => setType('url')}
          className={`cursor-pointer w-32 h-9 text-sm font-bold border-[3px] border-c-bg rounded-r flex items-center justify-center ${
            type === 'url' ? 'bg-white text-base' : 'bg-c-bg text-c-light'
          }`}>
          URL
        </button>
        <div className="flex justify-end w-full">
          <button
            className="cursor-pointer bg-white border border-border text-sm hover:opacity-50 rounded items-center justify-center h-10 px-6 mr-4"
            onClick={() => setDownloadModalVisible(true)}>
            ダウンロード
          </button>
        </div>
      </div>
      <div>
        {type === 'prospect' && (
          <MailCampaignSendsTable mailCampaign={mailCampaign} />
        )}
        {type === 'url' && <UrlsTable />}
      </div>
      <DownloadModal
        visible={downloadModalVisible}
        onClose={() => setDownloadModalVisible(false)}
      />
    </div>
  );
};
